import { GIGG_LOGIN_NAME, GIGG_SESSION_NAME } from './consts';
import { WEEK } from './TimePeriods';
import { userType } from './services/useAuthProvider';

export function getCookie(cName: string) {
  const name = `${cName}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getLocal(key: string) {
  const itemStored = window.localStorage.getItem(key);
  if (!itemStored) {
    return null;
  }
  return itemStored;
}

export function getGiggToken(key: string = GIGG_LOGIN_NAME) {
  const cookie = getCookie(key);
  const local = getLocal(key);
  if ((!cookie && !local) || local === null) {
    return null;
  }
  const { token } = JSON.parse(cookie || local);
  return token;
}



export const isIframe = () => window.self !== window.top;

export const isSSOLogin = () => {
    const localAuth = getGiggToken(GIGG_SESSION_NAME);
    return !!localAuth && isIframe();
};

export function invalidateLogin(storedName: string) {
  const d = new Date(0);
  d.setUTCSeconds(WEEK / 1000);
  document.cookie = `${storedName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  localStorage.removeItem(storedName);
}

export const authLogout = ({
  user,
  socialLogout,
  logout,
}: {
  user: userType;
  socialLogout: Function;
  logout: Function;
}): void => {
  return logout();
};
