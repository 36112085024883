export const enum StageTypes {
  SUBMISSION = 'SUBMISSION',
  QUALIFYING = 'QUALIFYING',
  BRACKET = 'BRACKET',
  WINNERS = 'WINNERS',
}

type stageTypeOption = {
  [key: string]: 'SUBMISSION' | 'QUALIFYING' | 'BRACKET' | 'WINNERS';
};

export const STAGE_TYPES: stageTypeOption = {
  [StageTypes.SUBMISSION]: 'SUBMISSION',
  [StageTypes.QUALIFYING]: 'QUALIFYING',
  [StageTypes.BRACKET]: 'BRACKET',
  [StageTypes.WINNERS]: 'WINNERS',
};

export const STAGE_ROUTES = {
  [STAGE_TYPES.submission]: 'submission',
  [STAGE_TYPES.qualifying]: 'qualifying',
  [STAGE_TYPES.bracket]: 'bracket',
  [STAGE_TYPES.winners]: 'winners',
};

export const BASE_URLS = {
  PROD: 'https://challenges.gigg.com/',
  DEV: 'https://gigg-web-dev.netlify.app/',
  LOCAL: 'http://localhost:3002/',
  BUSINESS: 'https://www.gigg.com/',
};

export const Services = {
  YOUTUBE: 'youtube',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  CUSTOM: 'custom',
  INSTAGRAM_GRAPH: 'instagramGraph',
  FACEBOOK: 'facebook',
  OPEN_GRAPH: 'openGraph',
  GIGG: 'gigg',
  TIK_TOK: 'tikTok',
};

export const CAPTCHA_TYPE = 'BRACKETS';

export const GIGG_LOGIN_NAME = 'gigg-public-auth-id';
export const GIGG_SESSION_NAME = 'gigg-session-id';

export const GIGG_LOGIN_EVENT = 'gigglogin';
export const GIGG_LOGOUT_EVENT = 'gigglogout';

export const SOCIAL_PLATFORMS = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  PINTEREST: 'pinterest',
  OAUTH: 'oauth',
  GIGG: 'gigg',
};
