import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import jwt from 'jsonwebtoken';
import { useMutation } from 'react-query';
import firebase from '../firebase-config';
import axios from '../app/axios';
import {
  CAPTCHA_TYPE,
  GIGG_LOGIN_EVENT,
  GIGG_LOGIN_NAME,
  GIGG_LOGOUT_EVENT,
  Services,
  SOCIAL_PLATFORMS,
} from '../consts';
import { getGiggToken, invalidateLogin } from '../utilities';

export interface userType {
  status: string;
  type: string;
  id: string;
  name: string;
  pictureUrl?: string;
}

const useAuthProvider = () => {
  const [user, setUser] = useState<userType>();
  const [errorMessage /* setErrorMessage */] = useState<string>();
  const token = useRef<string>(getGiggToken());

  const firebaseAuth = getAuth(firebase);

  const loginMutation = useMutation((payload: any) => {
    const { captchaToken } = payload;
    return axios.post('/auth/public-auth/login', payload, {
      headers: { 'captcha-token': captchaToken, 'captcha-type': CAPTCHA_TYPE },
    });
  });

  const userEnteredMutation = useMutation((compId) =>
    axios.post(`/public/competition/${compId}/posts/socialidexists`, {
      socialId: user?.id,
    })
  );

  const logout = () => {
    window.dispatchEvent(new Event(GIGG_LOGOUT_EVENT));
  };

  const handleGiggLogout = useCallback(() => {
    invalidateLogin(GIGG_LOGIN_NAME);
    setUser(undefined);
    axios.defaults.headers.common['social-code'] = '';
    axios.defaults.headers.common['social-type'] = '';
    loginMutation.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const socialLogout = async () => {
    await firebaseAuth.signOut();
    axios.defaults.headers.common['social-code'] = '';
    axios.defaults.headers.common['social-type'] = '';
    window.dispatchEvent(new CustomEvent('socialLogout'));
    setUser(undefined);
  };

  const handleGiggLogin = useCallback(() => {
    token.current = getGiggToken();
    if (token.current) {
      axios.defaults.headers.common['social-code'] = token.current;
      axios.defaults.headers.common['social-type'] = SOCIAL_PLATFORMS.GIGG;
      const { uid, displayName } = jwt.decode(token.current) as any;
      setUser({
        status: 'connected',
        type: Services.GIGG,
        id: uid,
        name: displayName,
      });
    }
  }, [token]);

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (firebaseUser) => {
      if (firebaseUser) {
        const providerData = firebaseUser.providerData[0];
        axios.defaults.headers.common['social-type'] = providerData.providerId.includes('google')
          ? SOCIAL_PLATFORMS.GOOGLE
          : SOCIAL_PLATFORMS.FACEBOOK;
        axios.defaults.headers.common['social-code'] = firebaseUser.uid;
        setUser({
          status: 'connected',
          type: providerData.providerId.includes('google')
            ? SOCIAL_PLATFORMS.GOOGLE
            : SOCIAL_PLATFORMS.FACEBOOK,
          id: firebaseUser.uid,
          name: firebaseUser.displayName || '',
          pictureUrl: providerData.photoURL || '',
        });
      } else {
        setUser((user) =>
          user && user?.type !== Services.GIGG ? undefined : user
        );
      }
    });
  }, [firebaseAuth]);

  useEffect(() => {
    handleGiggLogin();
    window.addEventListener(GIGG_LOGIN_EVENT, handleGiggLogin);
    window.addEventListener(GIGG_LOGOUT_EVENT, handleGiggLogout);

    return () => {
      window.removeEventListener(GIGG_LOGIN_EVENT, handleGiggLogin);
      window.addEventListener(GIGG_LOGOUT_EVENT, handleGiggLogout);
    };
  }, [handleGiggLogin, handleGiggLogout]);

  return {
    loginMutation,
    userEnteredMutation,
    logout,
    user,
    socialLogout,
    errorMessage,
  };
};

const AuthContext = createContext<any>(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
